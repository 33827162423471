import { mapV2 } from '@/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { AuthActions } from '../Auth/Auth.redux';
import { storage } from '../Store.persist';
import {
	DrawTypeEnum,
	IDialSettingJson,
	IEventItem,
	IEventJson,
	IPayloadCreateEventRequest,
	IPayloadGetEventListSuccess,
	IPayloadGetSettingByEventIdRequest,
	IPayloadGetSettingByEventIdSuccess,
	IPayloadUpdateDialSetting,
	IPayloadUpdateEventRequest,
	IPayloadUpdatePrizeListRequest,
	IPayloadUpdateResultRequest,
	ResourceTypeEnum,
} from './LuckyDraw.type';

import { mergeDeepRight } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { persistReducer } from 'redux-persist';

const initialState = {
	fetching: false,
	eventJson: {
		0: {
			id: 0,
			name: '',
			startDate: '',
		},
	} as IEventJson,
	dialSettingJson: {
		0: {
			id: 0,
			drawType: DrawTypeEnum.AUTO,
			drawDuration: 1, // s
			drawCount: 1,
			prizeNumber: 3,
			minNumber: 0,
			maxNumber: 999,
			resourceType: ResourceTypeEnum.RANGE_NUM,
			prizeList: [
				{
					id: 1,
					name: 'Giải Ba',
					resultCount: 10,
					results: [],
				},
				{
					id: 2,
					name: 'Giải Nhì',
					resultCount: 5,
					results: [],
				},
				{
					id: 3,
					name: 'Giải nhất',
					resultCount: 1,
					results: [],
				},
			],
			participants: [],
		},
	} as IDialSettingJson,
	eventList: [] as IEventItem[],
	selectedEventId: 0,
};

const luckyDrawSlice = createSlice({
	initialState,
	name: 'luckyDraw',
	reducers: {
		/**----UPDATE DIAL SETTING START----*/
		updateDialSettingRequest: (
			state,
			action: PayloadAction<IPayloadUpdateDialSetting>
		) => {
			const { eventId, setting } = action?.payload;

			if (!eventId) {
				state.dialSettingJson[0] = setting;
				return;
			}

			state.fetching = true;
		},
		updateDialSettingSuccess: (state) => {
			state.fetching = false;
		},
		updateDialSettingFailure: (state) => {
			state.fetching = false;
		},
		/**----UPDATE DIAL SETTING END----*/

		/**----GET SETTING BY EVENT ID START----*/
		getSettingByEventIdRequest: (
			state,
			action: PayloadAction<IPayloadGetSettingByEventIdRequest>
		) => {
			state.fetching = true;
		},
		getSettingByEventIdSuccess: (
			state,
			action: PayloadAction<IPayloadGetSettingByEventIdSuccess>
		) => {
			const { eventId, setting } = action.payload;

			state.fetching = false;
			state.dialSettingJson = mergeDeepRight(state.dialSettingJson, {
				[eventId]: setting,
			});
		},
		getSettingByEventIdFailure: (state) => {
			state.fetching = false;
		},
		/**----GET SETTING BY EVENT ID END----*/

		/**----CREATE EVENT START----*/
		createEventRequest: (
			state,
			action: PayloadAction<IPayloadCreateEventRequest>
		) => {
			state.fetching = true;
		},
		createEventSuccess: (state) => {
			state.fetching = false;
		},
		createEventFailure: (state) => {
			state.fetching = false;
		},
		/**----CREATE EVENT END----*/

		/**----UPDATE EVENT START----*/
		updateEventRequest: (
			state,
			action: PayloadAction<IPayloadUpdateEventRequest>
		) => {
			state.fetching = true;
		},
		updateEventSuccess: (state) => {
			state.fetching = false;
		},
		updateEventFailure: (state) => {
			state.fetching = false;
		},
		/**----UPDATE EVENT END----*/

		/**----GET EVENT LIST START----*/
		getEventListRequest: (state) => {
			state.fetching = true;
		},
		getEventListSuccess: (
			state,
			action: PayloadAction<IPayloadGetEventListSuccess>
		) => {
			const { eventJson, eventList } = action.payload;
			state.fetching = false;
			state.eventList = eventList;
			state.eventJson = mergeDeepRight(state.eventJson, eventJson);
			if (isNotNilOrEmpty(eventList) && !state.selectedEventId) {
				state.selectedEventId = eventList?.[0]?.id;
			}
		},
		getEventListFailure: (state) => {
			state.fetching = false;
		},
		/**----GET EVENT LIST END----*/

		/**----UPDATE RESULT START----*/
		updateResultRequest: (
			state,
			action: PayloadAction<IPayloadUpdateResultRequest>
		) => {
			const { eventId, prizeIndex, results } = action.payload;

			if (state.dialSettingJson[eventId].prizeList[prizeIndex]?.results) {
				state.dialSettingJson[eventId].prizeList[prizeIndex].results = [
					...(state.dialSettingJson?.[0]?.prizeList?.[prizeIndex]
						?.results || []),
					...results,
				];
			} else {
				state.dialSettingJson[eventId].prizeList[prizeIndex] = {
					...(state.dialSettingJson[eventId].prizeList[prizeIndex] ||
						{}),
					results: results,
				};
			}

			state.fetching = true;
		},
		updateResultSuccess: (state) => {
			state.fetching = false;
		},
		updateResultFailure: (state) => {
			state.fetching = false;
		},
		/**----UPDATE RESULT END----*/

		/**----UPDATE PRIZE LIST START----*/
		updatePrizeListRequest: (
			state,
			action: PayloadAction<IPayloadUpdatePrizeListRequest>
		) => {
			const { eventId, prizeList } = action.payload;

			if (!eventId) {
				state.dialSettingJson[0].prizeList = prizeList;

				return;
			}

			state.fetching = true;
		},
		updatePrizeListSuccess: (state) => {
			state.fetching = false;
		},
		updatePrizeListFailure: (state) => {
			state.fetching = false;
		},
		/**----UPDATE PRIZE LIST END----*/

		/**----RESET PRIZE RESULTS START----*/
		resetResultListRequest: (state) => {
			state.fetching = true;
			state.dialSettingJson[state.selectedEventId].prizeList = mapV2(
				state.dialSettingJson[state.selectedEventId].prizeList,
				(item) => ({
					...item,
					results: [],
				})
			);
		},
		resetResultListSuccess: (state) => {
			state.fetching = false;
		},
		resetResultListFailure: (state) => {
			state.fetching = false;
		},
		/**----RESET PRIZE RESULTS END----*/

		setSelectedEvent: (state, action: PayloadAction<number>) => {
			state.selectedEventId = action.payload;
		},

		resetLuckyDraw: (state) => {
			return initialState;
		},
	},
	extraReducers(builder) {
		builder.addCase(AuthActions.logoutRequest, (state) => {
			return {
				...initialState,
			};
		});
	},
});

export const LuckyDrawSelectors = {
	selectLuckyDrawFetching: (state: RootState) => state.luckyDraw.fetching,
	selectDialSettingById: (eventId: number) => (state: RootState) =>
		state.luckyDraw.dialSettingJson?.[eventId],

	selectSelectedDialSetting: (state: RootState) =>
		state.luckyDraw.dialSettingJson?.[state.luckyDraw.selectedEventId] ??
		state.luckyDraw.dialSettingJson?.[0],

	selectEventList: (state: RootState) => {
		return mapV2(
			state.luckyDraw.eventList,
			(item) => state.luckyDraw.eventJson?.[item?.id]
		);
	},
	selectEventById: (eventId: number) => (state: RootState) =>
		state.luckyDraw.eventJson[eventId],
	selectSelectedEvent: (state: RootState) =>
		state.luckyDraw.eventJson[state.luckyDraw.selectedEventId],
	selectSelectedEventId: (state: RootState) =>
		state.luckyDraw.selectedEventId,
};

export const LuckyDrawActions = luckyDrawSlice.actions;
export const LUCKY_DRAW_PERSIST_KEY = 'luckyDrawPersist';
export const LuckyDrawReducer = persistReducer(
	{
		storage,
		key: LUCKY_DRAW_PERSIST_KEY,
		whitelist: [
			'setting',
			'eventJson',
			'dialSettingJson',
			'selectedEventId',
		],
	},
	luckyDrawSlice.reducer
);
