import { createContactApi } from '@/services/contact.api';
import { createProfileApi } from '@/services/profile.api';

import { createServiceAdminApi } from './api.admin';
import { createCommonApi } from './common.api';
import { createLuckyDrawApi } from './lucky-draw.api';
import { createUpgradeApi } from './upgrade.api';

import apisauce, { ApiResponse } from 'apisauce';

export const isDev = process.env.NODE_ENV === 'development';

export const baseURL = process.env.NEXT_PUBLIC_API_URL;

export const defaultApiSauceConfig = (headers?: any) => {
	return {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...headers,
		},
		timeout: 10000,
	};
};

const createServiceApi = () => {
	const api = apisauce.create({
		...defaultApiSauceConfig(),
		baseURL: baseURL,
	});

	return {
		api,
		...createCommonApi(api),
		...createLuckyDrawApi(api),
		...createServiceAdminApi(api),
		...createProfileApi(api),
		...createContactApi(api),
		...createUpgradeApi(api),
		// ...createSendEmailApi(api)
	};
};

export const ServiceApi = createServiceApi();

export const setApiAuthorization = (token: string) => {
	ServiceApi.api.setHeaders({
		authorization: 'Bearer ' + token,
	});
};

export const removeToken = () => {
	ServiceApi.api.deleteHeader('authorization');
};

export const isSuccess = <T = any>(res: ApiResponse<T> & any) => {
	return res.ok && (res.status === 200 || res.status === 204);
};
