import { GetUpgradePackageResponse, IUpgradeParams } from '.';

import { ApisauceInstance } from 'apisauce';

export const createUpgradeApi = (api: ApisauceInstance) => {
	const upgrade = async (body: IUpgradeParams) => api.post('/upgrades', body);

	const getUpgradePackageByEventId = (packageId: number) =>
		api.get<GetUpgradePackageResponse>(`/upgrades/${packageId}/package`);

	return {
		upgrade,
		getUpgradePackageByEventId,
	};
};
