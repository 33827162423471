import { IContact, isSuccess, ServiceApi } from '@/services';
import { ContactActions } from '@/store/Contact/Contact.redux';
import { PayloadAction } from '@reduxjs/toolkit';

import { ApiResponse } from 'apisauce';
import { put } from 'redux-saga/effects';

export function* createContact(action: PayloadAction<any>) {
	try {
		const { data, callback } = action.payload;
		const res: ApiResponse<IContact> = yield ServiceApi.createContact(data);
		if (isSuccess(res)) {
			yield put(ContactActions.createContactSuccess);
			callback?.();
		} else {
			yield put(ContactActions.createContactFailure);
		}
	} catch (e) {
		yield put(ContactActions.createContactFailure);
	}
}
