import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

export enum AudioType {
	NONE,
	CLICK = '/sounds/game-click-s2.mp3',
	TURN_ON = '/sounds/sm-turnon.mp3',
	TURN_OFF = '/sounds/sm-turnoff.mp3',
	ROLLER_LOOP = '/sounds/sm-roller-loop.mp3',
	SPIN = '/sounds/sm-spin.mp3',
	BET = '/sounds/sm-bet.mp3',
	JACKPOT_COMING = '/sounds/game-sm-jackpot-coming.mp3',
	JACKPOT_WIN = '/sounds/game-sm-jackpot-win.mp3',
	COUNTDOWN = '/sounds/game-countdown.mp3',
	TADA = '/sounds/game-tada.mp3',
	WINNER = '/sounds/fanfare-winner.mp3',
	LEVEL_UP = '/sounds/levelup-s3.mp3',
	GAME_OVER = '/sounds/game-over.mp3',
	GAME_BONUS = '/sounds/game-bonus.mp3',
	SWOOSH = '/sounds/swoosh.mp3',
}

const initialState: IAudioState = {
	playing: false,
	isSound: false,
	audioType: null,
	isDraw: false,
	isDrawSuccess: false,
};
type IAudioState = {
	playing: boolean;
	isSound: boolean;
	isDraw: boolean;
	isDrawSuccess: boolean;
	audioType: AudioType | null;
};

const audioSlice = createSlice({
	initialState,
	name: 'Audio',
	reducers: {
		playDrawRequest: (state) => {
			state.isDrawSuccess = false;
			state.isDraw = true;
		},
		playDrawSuccess: (state) => {
			state.isDrawSuccess = true;
			state.isDraw = false;
		},
		playDrawFinally: (state) => {
			state.isDrawSuccess = false;
		},
		playAudioRequest: (state, action: PayloadAction<AudioType | null>) => {
			state.playing = true;
			state.audioType = action.payload;
		},
		stopAudioRequest: (state) => {
			state.playing = false;
			state.audioType = null;
		},
		disabledSoundRequest(state) {
			state.isSound = !state.isSound;
		},
	},
});

export const AudioSelectors = {
	selectAudioType: (state: RootState) => state.audio.audioType,
	selectAudioPlaying: (state: RootState) => state.audio.playing,
	selectIsSound: (state: RootState) => state.audio.isSound,
	selectIsDraw: (state: RootState) => state.audio.isDraw,
	selectIsDrawSuccess: (state: RootState) => state.audio.isDrawSuccess,
};

export const AudioActions = audioSlice.actions;
export const AudioReducer = audioSlice.reducer;
