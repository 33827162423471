import { createSlice } from '@reduxjs/toolkit';

const startupSlice = createSlice({
	initialState: {},
	name: 'startup',
	reducers: {
		startup: (state) => {},
	},
});

export const StartupSelectors = {};

export const StartupActions = startupSlice.actions;
export const StartupReducer = startupSlice.reducer;
