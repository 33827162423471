import { IContact } from '@/services/api.type';

import { ApisauceInstance } from 'apisauce';

export const createContactApi = (api: ApisauceInstance) => {
	const createContact = async (body: IContact) => api.post(`/contacts`, body);

	return {
		createContact,
	};
};
