import { AudioActions, getAudio } from '@/store/Audio';
import { ContactActions } from '@/store/Contact/Contact.redux';
import { createContact } from '@/store/Contact/Contact.saga';
import { createSendEmail, SendEmailActions } from '@/store/SendEmail';

import {
	AuthActions,
	changePassword,
	forgotPassword,
	loginGoogleRequest,
	loginRequest,
	logout,
	register,
	resetPassword,
} from './Auth';
import { GeneralActions, getGeneral } from './General';
import { LuckyDrawActions } from './LuckyDraw';
import {
	createEvent,
	getEventList,
	getSettingByEventId,
	resetResultList,
	updateDialSetting,
	updateEvent,
	updateResult,
} from './LuckyDraw/LuckyDraw.saga';
import { getMyProfile, ProfileActions } from './Profile';
import { startup, StartupActions } from './Startup';

import { all, takeEvery, takeLatest } from 'redux-saga/effects';
/* ------------- Connect Types To Sagas ------------- */
export default function* rootSaga() {
	yield all([
		//STARTUP
		takeEvery(StartupActions.startup.type, startup),

		// AUTHENTICATION
		takeEvery(AuthActions.logoutRequest.type, logout),
		takeEvery(AuthActions.loginRequest.type, loginRequest),
		takeEvery(AuthActions.loginGoogleRequest.type, loginGoogleRequest),
		takeEvery(AuthActions.registerRequest.type, register),
		takeEvery(AuthActions.forgotPasswordRequest.type, forgotPassword),
		takeEvery(AuthActions.resetPasswordRequest.type, resetPassword),
		takeEvery(AuthActions.changePasswordRequest.type, changePassword),

		// PROFILE
		takeEvery(ProfileActions.getProfileRequest.type, getMyProfile),

		// GENERAL
		takeEvery(GeneralActions.getGeneralRequest.type, getGeneral),

		// LUCKY DRAW
		takeLatest(LuckyDrawActions.createEventRequest.type, createEvent),
		takeLatest(LuckyDrawActions.updateEventRequest.type, updateEvent),
		takeLatest(LuckyDrawActions.getEventListRequest.type, getEventList),
		takeLatest(
			LuckyDrawActions.getSettingByEventIdRequest.type,
			getSettingByEventId
		),
		takeLatest(
			LuckyDrawActions.updateDialSettingRequest.type,
			updateDialSetting
		),
		takeLatest(LuckyDrawActions.updateResultRequest.type, updateResult),
		takeLatest(
			LuckyDrawActions.resetResultListRequest.type,
			resetResultList
		),

		// CONTACT
		takeLatest(ContactActions.createContactRequest.type, createContact),

		// SEND EMAIL
		takeLatest(
			SendEmailActions.createSendEmailRequest.type,
			createSendEmail
		),

		// AUDIO
		takeLatest(AudioActions.playAudioRequest.type, getAudio),
	]);
}
