export const MODAL_ANIMATE_TYPE = {
	flip: {
		hidden: {
			transform: 'scale(0) rotateX(-360deg)',
			opacity: 0,
			transition: {
				delay: 0.3,
			},
		},
		visible: {
			transform: ' scale(1) rotateX(0deg)',
			opacity: 1,
			transition: {
				duration: 0.5,
			},
		},
		exit: {
			transform: 'scale(0) rotateX(360deg)',
			opacity: 0,
			transition: {
				duration: 0.5,
			},
		},
	},
	badSuspension: {
		hidden: {
			y: '-100vh',
			opacity: 0,
			transform: 'scale(0) rotateX(-360deg)',
		},
		visible: {
			y: '-25vh',
			opacity: 1,
			transition: {
				duration: 0.2,
				type: 'spring',
				damping: 15,
				stiffness: 500,
			},
		},
		exit: {
			y: '-100vh',
			opacity: 0,
		},
	},
	newspaper: {
		hidden: {
			transform: 'scale(0) rotate(720deg)',
			opacity: 0,
			transition: {
				delay: 0.3,
			},
		},
		visible: {
			transform: ' scale(1) rotate(0deg)',
			opacity: 1,
			transition: {
				duration: 0.5,
			},
		},
		exit: {
			transform: 'scale(0) rotate(-720deg)',
			opacity: 0,
			transition: {
				duration: 0.3,
			},
		},
	},

	dropIn: {
		hidden: {
			y: '-100vh',
			opacity: 0,
		},
		visible: {
			y: '0',
			opacity: 1,
			transition: {
				duration: 0.1,
				type: 'spring',
				damping: 25,
				stiffness: 500,
			},
		},
		exit: {
			y: '100vh',
			opacity: 0,
		},
	},
	dropInSelectButton: {
		hidden: {
			y: '60px',
			opacity: 0,
		},
		visible: {
			y: '0',
			opacity: 1,
			transition: {
				duration: 0.1,
				type: 'spring',
				damping: 25,
				stiffness: 500,
			},
		},
		exit: {
			y: '100px',
			opacity: 0,
		},
	},
	faded: {
		hidden: {
			opacity: 0,
		},
		visible: {
			opacity: 1,
			transition: {
				duration: 0.1,
				type: 'spring',
				damping: 25,
				stiffness: 500,
			},
		},
		exit: {
			opacity: 0,
		},
	},
};

export const MODAL_TYPES = {
	LIST_RESULT: 'list_result',
	LIST_EVENT: 'list_event',
	SETTING: 'show_setting',
	THEME: 'show_theme',
	LIST_OPTION: 'show_list_option',
	CONTACT_HELP: 'contact_help',
	INSTRUCT: 'instruct',
};
