import { useCallback } from 'react';

import { assoc } from 'ramda';

const useHandlers = <T extends {}>(input: T): T => {
	return Object.keys(input).reduce((handlers: any, keyHandler: string) => {
		// @ts-ignore
		// eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
		return assoc(keyHandler, useCallback(input[keyHandler], []), handlers);
	}, {});
};

export { useHandlers };
