import { ISendEmail } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: ISendEmailState = {
	fetching: true,
	sendEmail: null,
};

type ISendEmailState = {
	fetching: boolean;
	sendEmail: ISendEmail[] | null;
};
const sendEmailSlice = createSlice({
	initialState,
	name: 'sendEmail',
	reducers: {
		setSendEmail: (state, action: PayloadAction<any>) => {
			state.fetching = false;
			state.sendEmail = action.payload;
		},
		createSendEmailRequest: (state, action: PayloadAction<any>) => {
			state.fetching = true;
		},
		createSendEmailSuccess: (state) => {
			state.fetching = false;
		},
		createSendEmailFailure: (state) => {
			state.fetching = false;
		},
	},
});

export const SendEmailSelectors = {
	selectFetching: (state: RootState) => state.sendEmail.fetching,
	selectSendEmail: (state: RootState) => state.sendEmail.sendEmail,
};

export const SendEmailActions = sendEmailSlice.actions;
export const SendEmailReducer = sendEmailSlice.reducer;
