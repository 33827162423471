'use client';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useDataToRef, useHandlers, useMergingState } from '@/hooks';
import { useI18n } from '@/locales/client';
import { AudioActions, AudioType, GlobalDispatch } from '@/store';
import { GeneralActions, ModalType } from '@/store/General';
import {
	LuckyDrawActions,
	LuckyDrawSelectors,
} from '@/store/LuckyDraw/LuckyDraw.redux';
import {
	DrawTypeEnum,
	IEventDetail,
	ResourceTypeEnum,
} from '@/store/LuckyDraw/LuckyDraw.type';
import {
	getBtnMainLabel,
	getCodeMaxLength,
	getImageHome,
	getInitNumbers,
	getRestAutoSpinNumber,
	handleDataAfterSpin,
	mapV2,
} from '@/utils';

export enum NumberStatus {
	INIT = 'INIT',
	FLIPPING = 'FLIPPING',
	ENDING = 'ENDING',
	ENDED = 'ENDED',
	ERROR = 'ERROR',
}

const useHomeLogic = () => {
	const setting = useSelector(LuckyDrawSelectors.selectSelectedDialSetting);
	// console.log(222, setting);

	const eventList = useSelector(LuckyDrawSelectors.selectEventList);
	const selectedEvent = useSelector(LuckyDrawSelectors.selectSelectedEvent);
	const isRange = setting.resourceType === ResourceTypeEnum.RANGE_NUM;
	const isAutoDraw = setting.drawType === DrawTypeEnum.AUTO;
	const timerStop = useRef<NodeJS.Timeout>();
	const { t }: any = useI18n();

	const codeMaxLength = useMemo(
		() => getCodeMaxLength(setting?.participants),
		[setting?.participants]
	);

	const initNumbers: any = useMemo(
		() =>
			getInitNumbers({
				maxLength: isRange
					? setting.maxNumber?.toString()?.length
					: codeMaxLength,
			}),
		[isRange, setting.maxNumber, codeMaxLength]
	);

	const [state, setState] = useMergingState({
		status: NumberStatus.INIT,
		numbers: initNumbers,
		showCongratulation: false,
		showCreateEvent: false,
		showListOption: false,
		prizeIndex: 0,

		modalKey: '',

		changeSound: true,
		showLang: false,
	});

	const restAutoSpinNumber = useMemo(
		() =>
			getRestAutoSpinNumber({
				isAutoDraw,
				setting,
				prizeIndex: state.prizeIndex,
			}),
		[isAutoDraw, setting, state.prizeIndex]
	);

	const imageBadge = useMemo(
		() =>
			getImageHome({
				prizeLength: setting?.prizeList?.length,
				prizeIndex: state.prizeIndex,
			}),
		[setting, state.prizeIndex]
	);

	useEffect(() => {
		setState({ numbers: initNumbers });
	}, [initNumbers]);

	const flipping = state.status === NumberStatus.FLIPPING;

	const propsRef = useDataToRef({
		...state,
		initNumbersRef: initNumbers,
		settingRef: setting,
		isRangeRef: isRange,
		selectedEventRef: selectedEvent,
		codeMaxLengthRef: codeMaxLength,
		isAutoDrawRef: isAutoDraw,
		restAutoSpinNumberRef: restAutoSpinNumber,
		flippingRef: flipping,
	});

	useEffect(() => {
		let interval: any;
		if (flipping) {
			interval = setInterval(() => {
				// Tạo ngẫu nhiên 5 số từ 0 đến 9 cho từng chữ số
				const randomNumbers = state.numbers.map(() =>
					Math.floor(Math.random() * 10)
				);
				setState({ numbers: randomNumbers });
			}, 1000);
		} else {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [flipping, state.numbers, setState]);

	let isPlayDraw = false;
	const handlers = useHandlers({
		ON_CLICK_MAIN_BTN: () => {
			isPlayDraw = !isPlayDraw;
			isPlayDraw
				? GlobalDispatch(AudioActions.playDrawRequest())
				: GlobalDispatch(AudioActions.playDrawSuccess());
			const {
				settingRef,
				isRangeRef,
				selectedEventRef,
				codeMaxLengthRef,
				isAutoDrawRef,
				prizeIndex,
				restAutoSpinNumberRef,
				numbers,
				flippingRef,
			} = propsRef.current;

			if (timerStop.current) {
				clearTimeout(timerStop.current);
			}

			if (flippingRef) {
				const { randomNumbers, resultList } = handleDataAfterSpin({
					codeMaxLength: codeMaxLengthRef,
					isRange: isRangeRef,
					setting: settingRef,
				});

				setState({
					numbers: randomNumbers,
					status: NumberStatus.ENDING,
				});

				setTimeout(() => {
					GlobalDispatch(
						LuckyDrawActions.updateResultRequest({
							prizeIndex,
							eventId: selectedEventRef?.id,
							results: resultList,
						})
					);

					if (!isAutoDrawRef || restAutoSpinNumberRef === 1) {
						setState({
							showCongratulation: true,
							status: NumberStatus.ENDED,
						});

						setTimeout(() => {
							setState({
								showCongratulation: false,
								status: NumberStatus.INIT,
							});
						}, 5000);
					} else {
						handlers?.ON_CLICK_MAIN_BTN();
					}
				}, 5000);

				return;
			}

			const randomNumbers = numbers.map(() =>
				Math.floor(Math.random() * 10)
			);

			setState({
				status: NumberStatus.FLIPPING,
				numbers: randomNumbers,
			});

			// STOP ACTION
			if (isAutoDrawRef) {
				timerStop.current = setTimeout(() => {
					handlers?.ON_CLICK_MAIN_BTN();
				}, settingRef?.drawDuration * 1000);
			}
		},
		ON_TOGGLE_CREATE_EVENT: () => {
			GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
			setState((prevState) => ({
				...prevState,
				showCreateEvent: !prevState.showCreateEvent,
			}));
		},
		ON_TOGGLE_CONGRATULATION: () => {
			GlobalDispatch(AudioActions.playAudioRequest(AudioType.SWOOSH));
			setState((prevState) => ({
				...prevState,
				showCongratulation: !prevState.showCongratulation,
			}));
		},
		ON_SELECT_EVENT: (event: IEventDetail) => {
			GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
			GlobalDispatch(LuckyDrawActions.setSelectedEvent(event?.id));

			if (event?.id) {
				GlobalDispatch(
					LuckyDrawActions.getSettingByEventIdRequest({
						eventId: event?.id,
					})
				);
			}
		},

		ON_CHANGE_PRIZE_TAB: (activeKey: string) => {
			GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
			setState({ prizeIndex: Number(activeKey) });
		},
		ON_SHOW: (key: ModalType) => {
			GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
			GlobalDispatch(GeneralActions.setModalType(key));
		},
		ON_SOUND: () => {
			GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
			GlobalDispatch(AudioActions.disabledSoundRequest());
			setState((prevState) => ({ changeSound: !prevState.changeSound }));
		},
		ON_LANG: () => {
			GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
			setState({ showLang: true });
		},
		ON_NEXT_PRIZE: () => {
			GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
			const { settingRef } = propsRef.current;

			setState((prevState) => ({
				prizeIndex: Math.min(
					settingRef?.prizeList?.length - 1 || 0,
					prevState?.prizeIndex + 1
				),
			}));
		},
		ON_PREVIOUS_PRIZE: () => {
			GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
			setState((prevState) => ({
				prizeIndex: Math.max(0, prevState?.prizeIndex - 1),
			}));
		},
		CLOSE: () => {
			GlobalDispatch(AudioActions.playAudioRequest(AudioType.TURN_OFF));
			setState({ modalKey: '' });
		},
	});

	const eventDropdownData = useMemo(
		() =>
			mapV2(eventList, (item) => ({
				key: item.id,
				className: '!p-0 !mx-0',
				label: (
					<div
						className="border-0 border-b-[1px] border-solid border-b-[#F1F1F1] py-3 text-center text-base"
						onClick={() => handlers.ON_SELECT_EVENT(item)}
					>
						{item?.name}
					</div>
				),
			})).concat([
				{
					key: -1,
					className: '!p-0 !mx-0',
					label: (
						<div
							className="py-3 text-center text-base font-semibold text-[#9C3DF5]"
							onClick={handlers.ON_TOGGLE_CREATE_EVENT}
						>
							{'+ Tạo sự kiện'}
						</div>
					),
				},
			]),
		[eventList]
	);

	const drawDone =
		setting?.prizeList?.[state.prizeIndex]?.results?.length >=
		(setting?.prizeList?.[state.prizeIndex]?.resultCount || 100);

	const ending = state.status === NumberStatus.ENDING;

	useEffect(() => {
		const keyDownHandler = (event: any) => {
			const modalHide = document.body.style.overflow !== 'hidden';

			if (event.key === 'Enter' && modalHide && !drawDone && !ending) {
				event.preventDefault();
				handlers.ON_CLICK_MAIN_BTN();
			}
		};
		document.addEventListener('keydown', keyDownHandler);
		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [drawDone, ending, state.modalKey, state.showCreateEvent]);

	const btnMainLabel = getBtnMainLabel({
		drawDone,
		t,
		status: state.status,
	});

	return {
		state,
		handlers,
		eventDropdownData,
		selectedEvent,
		drawDone,
		isAutoDraw,
		ending,
		eventList,
		restAutoSpinNumber,
		setting,
		setState,
		imageBadge,
		btnMainLabel,
		t,
	};
};

export default useHomeLogic;
