import { isSuccess } from './api';
import {
	IContact,
	IEvent,
	IParticipant,
	IUpgradeParams,
	IUser,
	UpdateUserBody,
} from './api.type';
import { Filter } from './loopback.type';

import { ApisauceInstance } from 'apisauce';

export const createServiceAdminApi = (api: ApisauceInstance) => {
	const login = async (data?: { email: string; password: string }) =>
		api.post('/users/login', data);

	const getUsers = async (filter?: Filter) =>
		api.get<{ data: IUser[]; count: number }>('/admin/users', {
			filter,
		});

	const getUserById = async (userId: number, filter?: Filter) =>
		api.get('/admin/users/' + userId, {
			filter,
		});

	const editUser = async (userId: number, data?: UpdateUserBody) =>
		api.patch('/admin/users/' + userId, data);

	const editUserPackage = async (body: IUpgradeParams) =>
		api.post('/upgrades', body);

	const getGeneral = async () => api.get('/admin/general');

	const lockUserById = async (userId: number) =>
		api.post('/admin/lock-user/' + userId);

	const getAdminGeneral = async () => {
		const res = await api.get('/admin/general');

		if (isSuccess(res)) return res.data;

		return {};
	};

	const createMediaContent = async (data: any) =>
		api.post('/media-contents/', data, {
			headers: {
				...api.headers,
				'content-type': 'multipart/form-data',
			},
		});

	const getEvents = async (filter?: Filter) =>
		api.get<{ data: IEvent[]; count: number }>('/events', {
			filter,
		});

	const createEvent = async (data: IEvent) =>
		api.post<IEvent>('/events', data);

	const editEvent = async (id: number, data: IEvent) =>
		api.patch('/admin/events/' + id, data, {});

	const deleteEvent = async (id: number) => api.delete('/events/' + id);

	const deleteContact = async (id: number) => api.delete('/contacts/' + id);

	const getEventById = async (id: number, filter?: Filter) => {
		const res = await api.get<IEvent>('/events/' + id, { filter });

		if (isSuccess(res)) return res.data;

		return {};
	};

	const getParticipants = async (filter?: Filter) =>
		api.get<{ data: IParticipant[]; count: number }>('/participants', {
			filter,
		});

	const createParticipant = async (data: IParticipant) =>
		api.post('/participants', data);

	const importParticipant = async (data: IParticipant[]) =>
		api.post('/participants/import', data);

	const editParticipant = async (id: number, data: IParticipant) =>
		api.patch('/participants/' + id, data, {});

	const deleteParticipant = async (id: number) =>
		api.delete('/participants/' + id);

	const getParticipantById = async (id: number, filter?: Filter) => {
		const res = await api.get<IParticipant>('/participants/' + id, {
			filter,
		});

		if (isSuccess(res)) return res.data;

		return {};
	};

	const getContacts = async (filter?: Filter) =>
		api.get<{ data: IContact[]; count: number }>('/admin/contacts', {
			filter,
		});
	return {
		getUsers,
		getUserById,
		editUser,
		editUserPackage,
		lockUserById,
		getAdminGeneral,
		createMediaContent,
		getEvents,
		createEvent,
		editEvent,
		deleteEvent,
		deleteContact,
		getEventById,
		getParticipants,
		importParticipant,
		createParticipant,
		editParticipant,
		deleteParticipant,
		getParticipantById,
		getGeneral,
		getContacts,
	};
};
