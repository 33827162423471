import every from 'lodash/every';
import isInteger from 'lodash/isInteger';
import uniq from 'lodash/uniq';

interface GetPageNumbersArgs {
	currentPage: number;
	pageSize: number;
	total: number;
	pageNumbersToShow?: number;
}

export const getPageNumbers = ({
	currentPage = 0,
	pageSize = 10,
	total = 0,
	pageNumbersToShow = 3,
}: GetPageNumbersArgs) => {
	const lastPageNumber = Math.ceil(total / pageSize);
	const currentPageNumber =
		currentPage <= lastPageNumber ? currentPage : lastPageNumber;
	const maxPagesBeforeCurrentPage = Math.floor(pageNumbersToShow / 2);
	const maxPagesAfterCurrentPage = Math.ceil(pageNumbersToShow / 2) - 1;
	let startPage = 1;
	let endPage = lastPageNumber;

	if (lastPageNumber <= 1) {
		return []; // Don't show numbers if there's only 1 page
	}

	if (currentPageNumber <= maxPagesBeforeCurrentPage) {
		// near the start
		startPage = 1;
		endPage = pageNumbersToShow;
	} else if (currentPageNumber + maxPagesAfterCurrentPage >= lastPageNumber) {
		// near the end
		startPage = lastPageNumber - pageNumbersToShow + 1;
	} else {
		// somewhere in the middle
		startPage = currentPageNumber - maxPagesBeforeCurrentPage;
		endPage = currentPageNumber + maxPagesAfterCurrentPage;
	}

	let pageNumbers: (string | number)[] = Array.from(
		Array(endPage + 1 - startPage).keys()
	)
		.map((pageNumber) => startPage + pageNumber)
		.filter((pageNumber) => pageNumber <= lastPageNumber && pageNumber > 0);

	if (Number(pageNumbers[0]) > 1) {
		if (Number(pageNumbers[0]) <= 2) {
			pageNumbers = [1, ...pageNumbers];
		} else {
			const ellipsis = Number(pageNumbers[0]) > 3 ? '...' : 2;
			pageNumbers = [1, ellipsis, ...pageNumbers];
		}
	}

	if (pageNumbers[pageNumbers.length - 1] !== lastPageNumber) {
		if (pageNumbers[pageNumbers.length - 1] === lastPageNumber - 1) {
			pageNumbers = [...pageNumbers, lastPageNumber];
		} else {
			const ellipsis =
				Number(pageNumbers[pageNumbers.length - 1]) < lastPageNumber - 2
					? '...'
					: lastPageNumber - 1;
			pageNumbers = [...pageNumbers, ellipsis, lastPageNumber];
		}
	}

	return pageNumbers;
};

export const getSizes = (customSizes?: number[]) => {
	const defaultSizes = [20, 40, 60, 80, 100];
	// only if customSizes is an array & all values are valid
	if (Array.isArray(customSizes) && every(customSizes, isInteger)) {
		const uniqSizes = uniq(customSizes).sort((a, b) => a - b);
		// if the evaluate array is empty, return default
		// otherwise, return evaluated values
		if (!uniqSizes.length) return defaultSizes;
		return uniqSizes;
	}
	// default
	return defaultSizes;
};
