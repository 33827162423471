import {
	IChangePasswordParams,
	IForgotPasswordParams,
	ILoginParams,
	ILoginResponse,
	IRegisterParams,
	IRegisterResponse,
	IResetPasswordParams,
	IUser,
} from './api.type';
import { Filter } from './loopback.type';

import { ApisauceInstance } from 'apisauce';

export const createCommonApi = (api: ApisauceInstance) => {
	const login = async (data: ILoginParams) =>
		api.post<ILoginResponse>('/users/login', data);

	const loginGoogle = async (payload: {
		id: string;
		token: string;
		firstName: string;
		lastName: string;
		email: string;
	}) => api.post<{ token: string }>('/users/login/google', payload);

	const register = async (data: IRegisterParams) =>
		api.post<IRegisterResponse>('/users/register', data);

	const getUserById = async (userId: number, filter?: Filter) =>
		api.get('/users/' + userId, {
			filter,
		});

	const getUserMe = async () => api.get<IUser>('/users/me');

	const forgotPassword = async (data: IForgotPasswordParams) =>
		api.post<IUser>('/users/reset-password/init', data);

	const resetPassword = async (data: IResetPasswordParams) =>
		api.put<IUser>('/users/reset-password/finish', data);

	const changePassword = async (data: IChangePasswordParams) =>
		api.patch<IUser>('/users/change-password', data);

	return {
		login,
		loginGoogle,
		register,
		getUserById,
		getUserMe,
		forgotPassword,
		resetPassword,
		changePassword,
	};
};
