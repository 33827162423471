import { PayloadAction } from '@reduxjs/toolkit';

export function* createSendEmail(action: PayloadAction<any>) {
	// try {
	// 	const { data, callback } = action.payload;
	// 	const res: ApiResponse<ISendEmail> = yield ServiceApi.createSendEmail(data);
	// 	if (isSuccess(res)) {
	// 		yield put(SendEmailActions.createSendEmailSuccess);
	// 		callback?.();
	// 	} else {
	// 		yield put(SendEmailActions.createSendEmailFailure);
	// 	}
	// } catch (e) {
	// 	yield put(SendEmailActions.createSendEmailFailure);
	// }
}
