import { IUser } from '@/services/api.type';

import { ApisauceInstance } from 'apisauce';

export const createProfileApi = (api: ApisauceInstance) => {
	const updateProfile = async (body: IUser) => api.patch(`/users/me`, body);

	return {
		updateProfile,
	};
};
