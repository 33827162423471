import { IUser } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { AuthActions } from '../Auth/Auth.redux';

const initialState: IProfileState = {
	fetching: true,
	profile: null,
};

type IProfileState = {
	fetching: boolean;
	profile: IUser | null;
};
const profileSlice = createSlice({
	initialState,
	name: 'profile',
	reducers: {
		/**----GET PROFILE START---- */
		getProfileRequest: (state) => {
			state.fetching = true;
		},
		getProfileSuccess: (state, action: PayloadAction<any>) => {
			state.fetching = false;
			state.profile = action.payload;
		},
		getProfileFailure: (state) => {
			state.fetching = false;
			state.profile = null;
		},

		resetProfile: (state) => {
			return initialState;
		},
		checkAccountRequest: (state) => {
			state.fetching = true;
		},
	},
	extraReducers(builder) {
		builder.addCase(AuthActions.loginSuccess, (state, action) => {
			const { user } = action.payload;
			state.profile = user;
		});
		builder.addCase(AuthActions.registerSuccess, (state, action) => {
			const { data } = action.payload;
			state.profile = data?.user;
		});
	},
});

export const ProfileSelectors = {
	selectProfile: (state: RootState) => state.profile.profile,
};

export const ProfileActions = profileSlice.actions;
export const ProfileReducer = profileSlice.reducer;
