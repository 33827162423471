import { insertIf } from '@/utils';

import { AuthSelector } from '../Auth';
import { LuckyDrawActions } from '../LuckyDraw';
import { ProfileActions } from '../Profile';

import { all, put, select } from 'redux-saga/effects';

export function* startup() {
	/** START UP */
	const logged: boolean = yield select(AuthSelector.selectLogged);
	yield all([
		put(ProfileActions.getProfileRequest()),
		...insertIf(logged, put(LuckyDrawActions.getEventListRequest())),
		...insertIf(!logged, put(LuckyDrawActions.setSelectedEvent(0))),
	]);
	// you can call something needs
}
