import { DATA_THEME } from '@/constant';
import { RootState } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
	...DATA_THEME[0],
	key_dial: '2',
	key_layout: '1',
};

const themeSlice = createSlice({
	initialState,
	name: 'theme',
	reducers: {
		updateTheme: (state, action: PayloadAction<any>) => {
			const {
				key,
				title,
				bg_image,
				gradient_draw_inside,
				bg_draw_inside,
				gradient_border_outside,
				bg_table,
				bg_table_prize,
				bg_btn,
				bg_modal_btn,
				bg_btn_icon,
				isSquare,
				isOverlay,
			} = action?.payload;

			// For common
			state.key = key;
			state.title = title;
			state.bg_image = bg_image;

			// For Draw
			state.gradient_draw_inside = gradient_draw_inside;
			state.bg_draw_inside = bg_draw_inside;
			state.gradient_border_outside = gradient_border_outside;

			// For table
			state.bg_table = bg_table;
			state.bg_table_prize = bg_table_prize;

			// For modal, btn icon
			state.bg_btn = bg_btn;
			state.bg_modal_btn = bg_modal_btn;
			state.bg_btn_icon = bg_btn_icon;
		},
		updateThemeSuccess: (state) => {},
		updateThemeFailure: (state) => {},

		updateDial: (state, action: PayloadAction<any>) => {
			const { key_dial } = action.payload;
			state.key_dial = key_dial;
		},
		updateDialSuccess: (state) => {},
		updateDialFailure: (state) => {},

		updateLayout: (state, action: PayloadAction<any>) => {
			const { key_layout } = action.payload;
			state.key_layout = key_layout;
		},
		updateLayoutSuccess: (state) => {},
		updateLayoutFailure: (state) => {},
	},
});

export const ThemeSelectors = {
	themeSelector: (state: RootState) => state.theme,
};

export const ThemeActions = themeSlice.actions;
export const ThemeReducer = themeSlice.reducer;
