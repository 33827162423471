import Swal from 'sweetalert2';

const Toast = Swal.mixin({
	toast: true,
	position: 'top-right',
	showConfirmButton: false,
	timer: 3000,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer);
		toast.addEventListener('mouseleave', Swal.resumeTimer);
	},
});

export const showToastError = (title?: string) =>
	Toast.fire({
		icon: 'error',
		title: title || 'Ops! Some thing wrong',
	});

export const showToastSuccess = (title?: string) =>
	Toast.fire({
		icon: 'success',
		title: title || 'Success',
	});

export const showConfirmAlert = ({
	title = 'Are you sure?',
	text = "You won't be able to revert this!",
	onConfirm,
	icon = 'info',
}: {
	title?: string;
	text?: string;
	icon?: 'warning' | 'error' | 'success' | 'info' | 'question';
	onConfirm?: () => void;
}) => {
	return Swal.fire({
		title,
		text,
		icon,
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: 'Yes, do it!',
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
			onConfirm?.();
		} else if (result.isDenied) {
		}
	});
};

export const showIncomingAlert = () => {
	return Swal.fire({
		title: '',
		text: '',
		icon: 'info',
		showCancelButton: true,
		showConfirmButton: false,
		// confirmButtonColor: "#3085d6",
		cancelButtonColor: '#02274D',
		cancelButtonText: '',
	});
};
