import bg_building from '@/app/assets/images/bg_building.png';
import bg_casino from '@/app/assets/images/bg_casino.png';
import bg_child from '@/app/assets/images/bg_child.png';
import bg_dial_1 from '@/app/assets/images/bg_draw_1.png';
import bg_dial_2 from '@/app/assets/images/bg_draw_2.png';
import bg_lucky from '@/app/assets/images/bg_lucky.png';
import bg_merry_ch from '@/app/assets/images/bg_merry_ch.png';
import bg_mid_autum from '@/app/assets/images/bg_mid_autum.png';
import bg_noel from '@/app/assets/images/bg_noel.png';
import bg_layout_1 from '@/app/assets/images/bg_overlay_1.png';
import bg_layout_2 from '@/app/assets/images/bg_overlay_2.png';
import bg_theme_small_1 from '@/app/assets/images/bg_theme_small_1.png';
import bg_theme_small_2 from '@/app/assets/images/bg_theme_small_2.png';
import bg_theme_small_3 from '@/app/assets/images/bg_theme_small_3.png';
import bg_theme_small_4 from '@/app/assets/images/bg_theme_small_4.png';
import bg_theme_small_5 from '@/app/assets/images/bg_theme_small_5.png';
import bg_theme_small_6 from '@/app/assets/images/bg_theme_small_6.png';
import bg_theme_small_7 from '@/app/assets/images/bg_theme_small_7.png';
import bg_theme_small_8 from '@/app/assets/images/bg_theme_small_8.png';
import bg_theme_small_9 from '@/app/assets/images/bg_theme_small_9.png';
import bg_valen from '@/app/assets/images/bg_valen.png';
import bg_woman from '@/app/assets/images/bg_woman.png';

export const MAX_PRIZE_COUNT = 4;

export enum PROFILE_TAB {
	INFORMATION,
	EVENT_MANAGEMENT,
	INVITATION_INTRODUCE,
	LOGOUT,
}

export const DIAL_KEY = {
	CIRCLE: '1',
	SQUARE: '2',
};

export const LAYOUT_KEY = {
	INSIDE: '1',
	OUTSIDE: '2',
};

export const DATA_THEME = [
	{
		key: '1',
		title: 'theme.default',
		bg_image: bg_lucky,
		bg_image_small: bg_theme_small_1,

		gradient_draw_inside:
			'linear-gradient(180deg, #C53DF5 0%, #9C3DF5 100%)',
		bg_draw_inside: '#33258D',
		gradient_border_outside:
			'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',

		bg_table: '#3A298F',
		bg_table_prize: '#2D2070',

		bg_btn: 'linear-gradient(180deg, #FF8A00 0%, #FFD600 100%)',
		bg_modal_btn: 'linear-gradient(90deg, #4A34BB 0%, #A863FF 100%)',
		bg_btn_icon: '#2D2070',
	},
	{
		key: '2',
		title: 'theme.neon',
		bg_image: bg_casino,
		bg_image_small: bg_theme_small_2,

		gradient_draw_inside: '#C933FF',
		bg_draw_inside: '#0D0050',
		gradient_border_outside:
			'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',

		bg_table: 'linear-gradient(180deg, #C933FF 0%, #260064 100%)',
		bg_table_prize: '#7B00A6',

		bg_btn: 'linear-gradient(180deg, #FF8A00 0%, #FFD600 100%)',
		bg_modal_btn: 'linear-gradient(90deg, #C933FF 0%, #260064 100%)',
		bg_btn_icon: '#0D0050',
	},
	{
		key: '3',
		title: 'theme.lunar_new_year',
		bg_image: bg_mid_autum,
		bg_image_small: bg_theme_small_3,

		gradient_draw_inside:
			'linear-gradient(180deg, #FF8A00 0%, #FFD600 100%)',
		bg_draw_inside: '#6D0709',
		gradient_border_outside:
			'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',

		bg_table: '#940B0D',
		bg_table_prize: '#6D0709',

		bg_btn: 'linear-gradient(180deg, #FF8A00 0%, #FFD600 100%)',
		bg_modal_btn: '#940B0D',
		bg_btn_icon: '#6D0709',
	},
	{
		key: '4',
		title: 'theme.year_and_party',
		bg_image: bg_merry_ch,
		bg_image_small: bg_theme_small_4,

		gradient_draw_inside:
			'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',
		bg_draw_inside: '#181818',
		gradient_border_outside:
			'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',

		bg_table:
			'linear-gradient(180deg, #000000 0%, #131313 0.01%, #2B2B2B 100%)',
		bg_table_prize: '#242424',

		bg_btn: 'linear-gradient(180deg, #FF8A00 0%, #FFD600 100%)',
		bg_modal_btn:
			'linear-gradient(90deg, #000000 0%, #131313 0.01%, #2B2B2B 100%)',
		bg_btn_icon: '#181818',
	},
	{
		key: '5',
		title: 'theme.women_day',
		bg_image: bg_woman,
		bg_image_small: bg_theme_small_5,

		gradient_draw_inside: '#FF3370',
		bg_draw_inside: '#D91538',
		gradient_border_outside:
			'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',

		bg_table: 'linear-gradient(180deg, #FF3370 0%, #FF5454 100%)',
		bg_table_prize: '#FF004D',

		bg_btn: '#FF3370',
		bg_modal_btn: 'linear-gradient(90deg, #FF3370 0%, #FF5454 100%)',
		bg_btn_icon: '#D91538',
	},
	{
		key: '6',
		title: 'theme.valentine',
		bg_image: bg_valen,
		bg_image_small: bg_theme_small_6,

		gradient_draw_inside:
			'linear-gradient(180deg, #FB7B81 -52.75%, #FB6D74 -20.07%, #FB515A 31.84%, #EF4851 49.14%, #D13139 83.75%, #A00B13 126.04%, #910007 139.5%)',
		bg_draw_inside: '#FF3939',
		gradient_border_outside:
			'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',

		bg_table:
			'linear-gradient(180deg, #FB7B81 -52.75%, #FB6D74 -20.07%, #FB515A 31.84%, #EF4851 49.14%, #D13139 83.75%, #A00B13 126.04%, #910007 139.5%)',
		bg_table_prize: '#FF3939',

		bg_btn: 'linear-gradient(180deg, #FB7B81 -52.75%, #FB6D74 -20.07%, #FB515A 31.84%, #EF4851 49.14%, #D13139 83.75%, #A00B13 126.04%, #910007 139.5%)',
		bg_modal_btn:
			'linear-gradient(90deg, #FB7B81 -52.75%, #FB6D74 -20.07%, #FB515A 31.84%, #EF4851 49.14%, #D13139 83.75%, #A00B13 126.04%, #910007 139.5%)',
		bg_btn_icon: '#FF3939',
	},
	{
		key: '7',
		title: 'theme.labor_international',
		bg_image: bg_building,
		bg_image_small: bg_theme_small_7,

		gradient_draw_inside:
			'linear-gradient(180deg, #FF8A00 0%, #FFD600 100%)',
		bg_draw_inside: '#090E32',
		gradient_border_outside:
			'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',

		bg_table: '#10153B',
		bg_table_prize: '#090E32',

		bg_btn: 'linear-gradient(180deg, #FF8A00 0%, #FFD600 100%)',
		bg_modal_btn: '#10153B',
		bg_btn_icon: '#090E32',

		bg_gradient: 'linear-gradient(180deg, #FF8A00 0%, #FFD600 100%)',
		bg_color: '#10153B',
		border_gradient: 'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',
	},
	{
		key: '8',
		title: 'theme.christmas',
		bg_image: bg_noel,
		bg_image_small: bg_theme_small_8,

		gradient_draw_inside:
			'linear-gradient(180deg, #FF8A00 0%, #FFD600 100%)',
		bg_draw_inside: '#17242B',
		gradient_border_outside:
			'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',

		bg_table: '#21333D',
		bg_table_prize: '#17242B',

		bg_btn: 'linear-gradient(180deg, #FF8A00 0%, #FFD600 100%)',
		bg_modal_btn: '#21333D',
		bg_btn_icon: '#17242B',
	},
	{
		key: '9',
		title: 'theme.international_children',
		bg_image: bg_child,
		bg_image_small: bg_theme_small_9,

		gradient_draw_inside: '#363275',
		bg_draw_inside: '#29255E',
		gradient_border_outside:
			'linear-gradient(180deg, #CCA333 0%, #EBD974 100%)',

		bg_table: '#363275',
		bg_table_prize: '#29255E',

		bg_btn: '#29255E',
		bg_modal_btn: '#363275',
		bg_btn_icon: '#29255E',
	},
];

export const DATA_DIAL = [
	{
		key_dial: '1',
		bg_dial_small: bg_dial_1,
		title: 'theme.dial_default',
	},
	{
		key_dial: '2',
		bg_dial_small: bg_dial_2,
		title: 'theme.dial_square',
	},
];
export const DATA_LAYOUT = [
	{
		key_layout: '1',
		bg_layout_small: bg_layout_1,
		title: 'theme.layout_table_outside',
	},
	{
		key_layout: '2',
		bg_layout_small: bg_layout_2,
		title: 'theme.layout_table_inside',
	},
];
