import { ROUTERS } from '@/constant';
import {
	ILoginResponse,
	IRegisterResponse,
	ServiceApi,
	setApiAuthorization,
} from '@/services';
import {
	deleteCookieAfterLogout,
	isSuccess,
	showToastError,
	showToastSuccess,
} from '@/utils';
import { PayloadAction } from '@reduxjs/toolkit';

import { ProfileActions } from '../Profile';
import { AuthActions } from './Auth.redux';
import {
	IPayloadChangePasswordRequest,
	IPayloadForgotPasswordRequest,
	IPayloadLoginRequest,
	IPayloadLogoutRequest,
	IPayloadRegisterRequest,
	IPayloadResetPasswordRequest,
} from './Auth.type';

import { ApiResponse } from 'apisauce';
import { put } from 'redux-saga/effects';

export function* logout(action: PayloadAction<IPayloadLogoutRequest>) {
	try {
		const { router } = action.payload;
		yield put(ProfileActions.resetProfile());
		deleteCookieAfterLogout();
		showToastSuccess('Đăng xuất thành công!');
		router.push(ROUTERS.HOME);
	} catch (error) {
		// TODO: something
	}
}

export function* loginRequest(action: PayloadAction<IPayloadLoginRequest>) {
	try {
		const { callback, ...rest } = action.payload;

		const res: ApiResponse<ILoginResponse> = yield ServiceApi.login(rest);
		if (isSuccess(res) && res?.data) {
			setApiAuthorization(res?.data?.token);
			yield put(
				AuthActions.loginSuccess({
					token: res?.data?.token,
					user: res?.data?.user,
				})
			);
			showToastSuccess('Đăng nhập thành công!');
			callback?.();
		} else {
			showToastError('Email hoặc mật khẩu không đúng');
			yield put(AuthActions.loginFailure());
		}
	} catch (e: any) {
		showToastError(e?.message);
		yield put(AuthActions.loginFailure());
	}
}

export function* loginGoogleRequest(action: PayloadAction<any>) {
	try {
		const { callback, ...rest } = action.payload;

		const res: ApiResponse<{
			id: string;
			token: string;
			firstName: string;
			lastName: string;
			email: string;
		}> = yield ServiceApi.loginGoogle(rest);
		if (isSuccess(res) && res?.data) {
			setApiAuthorization(res?.data?.token);
			yield put(
				AuthActions.loginGoogleSuccess({
					token: res?.data?.token,
				})
			);
			callback?.();
		} else {
			yield put(AuthActions.loginFailure);
		}
	} catch (e: any) {
		showToastError(e?.message);
		yield put(AuthActions.loginFailure);
	}
}

export function* register(action: PayloadAction<IPayloadRegisterRequest>) {
	try {
		const { callback, ...rest } = action.payload;

		const res: ApiResponse<IRegisterResponse> = yield ServiceApi.register(
			rest
		);

		if (isSuccess(res) && res?.data) {
			setApiAuthorization(res?.data?.token);
			yield put(AuthActions.registerSuccess({ data: res?.data }));
			showToastSuccess('Đã đăng ký thành công!');
			callback?.();
		} else {
			if (res?.data?.error?.constraint === 'uniqueEmail') {
				showToastError('Email đã được sử dụng');
			} else {
				showToastError('Đã có lỗi xảy ra');
			}

			yield put(AuthActions.registerFailure());
		}
	} catch (e: any) {
		showToastError(e?.message);
		yield put(AuthActions.registerFailure());
	}
}

export function* forgotPassword(
	action: PayloadAction<IPayloadForgotPasswordRequest>
) {
	try {
		const { callback, ...rest } = action.payload;

		const res: ApiResponse<IRegisterResponse> =
			yield ServiceApi.forgotPassword(rest);

		if (isSuccess(res)) {
			yield put(AuthActions.forgotPasswordSuccess());
			callback?.();
		} else {
			yield put(AuthActions.forgotPasswordFailure());
		}
	} catch (e: any) {
		showToastError(e?.message);
		yield put(AuthActions.forgotPasswordFailure());
	}
}

export function* resetPassword(
	action: PayloadAction<IPayloadResetPasswordRequest>
) {
	try {
		const { callback, ...rest } = action.payload;

		const res: ApiResponse<IRegisterResponse> =
			yield ServiceApi.resetPassword(rest);

		if (isSuccess(res)) {
			yield put(AuthActions.resetPasswordSuccess());
			callback?.();
		} else {
			yield put(AuthActions.resetPasswordFailure());
		}
	} catch (e: any) {
		showToastError(e?.message);
		yield put(AuthActions.resetPasswordFailure());
	}
}

export function* changePassword(
	action: PayloadAction<IPayloadChangePasswordRequest>
) {
	try {
		const { callback, ...rest } = action.payload;

		const res: ApiResponse<IRegisterResponse> =
			yield ServiceApi.changePassword(rest);

		if (isSuccess(res)) {
			yield put(AuthActions.changePasswordSuccess);
			callback?.();
		} else {
			yield put(AuthActions.changePasswordFailure);
		}
	} catch (e: any) {
		showToastError(e?.message);
		yield put(AuthActions.changePasswordFailure);
	}
}
