'use client';
import { ReactNode } from 'react';
import { useParams, usePathname, useRouter } from 'next/navigation';

import { translateWithVariables } from '@/locales/locale.util';

import en from './en.json';
import ja from './ja.json';
import ko from './ko.json';
import vi from './vi.json';
import zh from './zh.json';

import { includes, pathOr } from 'ramda';

const dictionaries = {
	en: en,
	vi: vi,
	ja: ja,
	ko: ko,
	zh: zh,
};

export const useI18n = () => {
	const params = useParams();
	const locale = params?.locale ? params?.locale.toString() : 'vi';

	const t = (key: string, param?: Record<string, ReactNode | string>) => {
		const content = pathOr(key, [locale, key], dictionaries);

		if (param) {
			return translateWithVariables(content, param);
		}
		return content;
	};
	return { t, lang: locale };
};
export const listLang = ['vi', 'en', 'zh', 'ko', 'ja'];
export const useChangeLocale = () => {
	const router = useRouter();
	const pathname = usePathname();
	const pathnames = pathname.split('/');

	const changeLanguage = (newLanguage: 'vi' | 'en' | 'zh' | 'ko' | 'ja') => {
		let nextPathname = pathname;
		if (newLanguage === 'vi') {
			pathnames.splice(1, 1);
			nextPathname = pathnames.join('/');
		} else if (includes(newLanguage, listLang)) {
			pathnames.splice(1, 1);
			nextPathname = '/' + newLanguage + pathnames.join('/');
		} else if (includes(newLanguage, listLang)) {
			nextPathname = '/' + newLanguage + pathname;
		}

		router.replace(nextPathname || '/');
	};

	return changeLanguage;
};
