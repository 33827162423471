import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { storage } from '../Store.persist';
import {
	IPayloadChangePasswordRequest,
	IPayloadForgotPasswordRequest,
	IPayloadLoginRequest,
	IPayloadLoginSuccess,
	IPayloadLogoutRequest,
	IPayloadRegisterRequest,
	IPayloadRegisterSuccess,
	IPayloadResetPasswordRequest,
} from './Auth.type';

import { persistReducer } from 'redux-persist';

const initialState: IAuthState = {
	fetching: false,
	token: null,
};

type IAuthState = {
	fetching: boolean;
	token: string | null;
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		/**----LOGIN START---- */
		loginRequest: (state, action: PayloadAction<IPayloadLoginRequest>) => {
			state.fetching = true;
		},
		loginSuccess: (state, action: PayloadAction<IPayloadLoginSuccess>) => {
			const { token } = action.payload;
			state.fetching = false;
			state.token = token;
		},
		loginFailure: (state) => {
			state.fetching = false;
		},

		/**----LOGIN START---- */
		loginGoogleRequest: (state, action: PayloadAction<any>) => {
			state.fetching = true;
		},
		loginGoogleSuccess: (state, action: PayloadAction<any>) => {
			const { token } = action.payload;
			state.fetching = false;
			state.token = token;
		},
		loginGoogleFailure: (state) => {
			state.fetching = false;
		},
		/**----LOGIN END---- */

		/**----LOGIN START---- */
		logoutRequest: (
			state,
			action: PayloadAction<IPayloadLogoutRequest>
		) => {
			state.fetching = false;
			state.token = null;
		},
		resetAuth: (state) => {
			return initialState;
		},
		resetFetching: (state) => {
			state.fetching = false;
		},
		/**----REGISTER START---- */
		registerRequest: (
			state,
			action: PayloadAction<IPayloadRegisterRequest>
		) => {
			state.fetching = true;
		},
		registerSuccess: (
			state,
			action: PayloadAction<IPayloadRegisterSuccess>
		) => {
			const { data } = action.payload;

			state.fetching = false;
			state.token = data?.token;
		},
		registerFailure: (state) => {
			state.fetching = false;
		},
		/**----REGISTER END---- */

		/**----FORGOT PASSWORD START---- */
		forgotPasswordRequest: (
			state,
			action: PayloadAction<IPayloadForgotPasswordRequest>
		) => {
			state.fetching = true;
		},
		forgotPasswordSuccess: (state) => {
			state.fetching = false;
		},
		forgotPasswordFailure: (state) => {
			state.fetching = false;
		},
		/**----FORGOT PASSWORD END---- */

		/**----RESET PASSWORD START---- */
		resetPasswordRequest: (
			state,
			action: PayloadAction<IPayloadResetPasswordRequest>
		) => {
			state.fetching = true;
		},
		resetPasswordSuccess: (state) => {
			state.fetching = false;
		},
		resetPasswordFailure: (state) => {
			state.fetching = false;
		},
		/**----RESET PASSWORD END---- */

		/**----CHANGE PASSWORD START---- */
		changePasswordRequest: (
			state,
			action: PayloadAction<IPayloadChangePasswordRequest>
		) => {
			state.fetching = true;
		},
		changePasswordSuccess: (state) => {
			state.fetching = false;
		},
		changePasswordFailure: (state) => {
			state.fetching = false;
		},
		/**----CHANGE PASSWORD END---- */
	},
});

export const AuthSelector = {
	selectAuthState: (state: RootState) => state.auth,
	selectFetching: (state: RootState) => state.auth.fetching,
	selectToken: (state: RootState) => state.auth.token,
	selectLogged: (state: RootState) => !!state.auth.token,
};

export const AuthActions = authSlice.actions;
export const AUTH_PERSIST_KEY = 'authPersist';
export const AuthReducer = persistReducer(
	{
		storage,
		key: AUTH_PERSIST_KEY,
		whitelist: ['token'],
	},
	authSlice.reducer
);
