import { IContact } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IContactState = {
	fetching: true,
	contact: null,
};

type IContactState = {
	fetching: boolean;
	contact: IContact | null;
};
const contactSlice = createSlice({
	initialState,
	name: 'contact',
	reducers: {
		createContactRequest: (state, action: PayloadAction<any>) => {
			state.fetching = true;
		},
		createContactSuccess: (state) => {
			state.fetching = false;
		},
		createContactFailure: (state) => {
			state.fetching = false;
		},
	},
});

export const ContactSelectors = {
	selectFetching: (state: RootState) => state.contact.fetching,
};

export const ContactActions = contactSlice.actions;
export const ContactReducer = contactSlice.reducer;
