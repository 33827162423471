export interface CurrencyType {
	[key: string]: string;
	VND: string;
	USD: string;
}

export interface CurrencyRateType {
	[key: string]: number;
	USD: number;
}

export enum PaymentStatusEnum {
	COMPLETED = 'COMPLETED',
}

export enum PackageIdEnum {
	FREE = 1,
	PERSONAL = 2,
	BUSINESS = 3,
}

export enum VATEnum {
	VAT_10 = 0.1,
}

export const CURRENCY: CurrencyType = {
	VND: 'it-IT',
	USD: 'en-US',
};

export const CURRENCY_RATE: CurrencyRateType = {
	VND: 1,
	USD: 25.457,
};
