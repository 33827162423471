import {
	CreateEventBodyRequest,
	CreateResultBody,
	GetEventListResponse,
	SettingItemResponse,
	UpdateSettingBody,
} from './api.type';
import { Filter } from './loopback.type';

import { ApisauceInstance } from 'apisauce';

export const createLuckyDrawApi = (api: ApisauceInstance) => {
	const createEvent = async (body: CreateEventBodyRequest) =>
		api.post('/events', body);

	const updateGuestEvent = async (
		eventId: number,
		body: CreateEventBodyRequest
	) => api.patch(`/events/${eventId}`, body);

	const getEventList = async (filter?: Filter) =>
		api.get<GetEventListResponse>('/events', filter);

	const deleteEvent = async (eventId: number) =>
		api.delete<GetEventListResponse>(`/events/${eventId}`);

	const getSettingByEventId = (eventId: number) =>
		api.get<SettingItemResponse>(`/events/${eventId}/settings`);

	const updateSetting = (settingId: number, body: UpdateSettingBody) =>
		api.patch<GetEventListResponse>(`/settings/${settingId}`, body);

	const createResult = (body: CreateResultBody) =>
		api.post<any>('/results', body);

	const resetPrizeResults = (eventId: number) =>
		api.post<any>(`/events/${eventId}/reset-prize-results`);

	return {
		createEvent,
		getEventList,
		deleteEvent,
		updateGuestEvent,
		getSettingByEventId,
		updateSetting,
		createResult,
		resetPrizeResults,
	};
};
