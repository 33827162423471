import { CURRENCY, CURRENCY_RATE } from '@/constant';

import moment from 'moment';

export const formatCurrency = (n: number | string, format: string = 'VND') => {
	return new Intl.NumberFormat(CURRENCY[format], {
		style: 'currency',
		currency: format,
	}).format(Math.ceil(Number(n) / CURRENCY_RATE[format]));
};

export const formatTableDate = (datetime?: string) => {
	if (!datetime) return '-';
	return moment(datetime).format('YYYY-MM-DD HH:mm');
};
export const formatTableDate2 = (date?: string) => {
	if (!date) return '';
	return moment(date).format('YYYY-MM-DD');
};
export const formatDate = (createdAt: string) => {
	return moment(createdAt).format('dddd, MMMM Do YYYY');
};

export const formatExpiredDate = (createdAt?: string) => {
	if (!createdAt) return '';
	return moment(createdAt).add(1, 'y').format('DD/MM/YYYY');
};

export const getFullName = (fullName?: string) => {
	if (fullName)
		return String(fullName || '')
			.trim()
			.substring(0, 2)
			.toUpperCase();
	else return 'NA';
};
