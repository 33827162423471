export const ROUTERS = {
	HOME: '/',
	LOGIN: '/login',
	LICENSE: '/license',
	PAYMENT: '/payment',

	SETTING_PROFILE: '/account/profile',
	SETTING_EVENT: '/account/events',

	ADMIN_DASHBOARD: '/admin/dashboard',

	ADMIN_LIST_EVENTS: '/admin/events',
	ADMIN_EVENT_DETAIL: '/admin/events/[eventId]',
	ADMIN_LIST_PARTICIPANT: '/admin/event/[eventId]/participant',
	ADMIN_ADD_PARTICIPANT: '/admin/event/[eventId]/participant/add',
	ADMIN_IMPORT_PARTICIPANT: '/admin/event/[eventId]/participant/import',
	ADMIN_EDIT_PARTICIPANT: '/admin/participant/[participantId]/edit',

	USERS: '/users',
	ADMIN_USERS: '/admin/users',
	ADMIN_USER_DETAIL: '/admin/users/[userId]',
	ADMIN_CONTACTS: '/admin/contacts',
};

export const navigation = [
	{
		label: 'menu.account_management',
		href: ROUTERS.SETTING_PROFILE,
	},
	{
		label: 'menu.event_management',
		href: ROUTERS.SETTING_EVENT,
	},
];
