import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

export enum ModalType {
	NONE,
	SIGN_IN,
	SIGN_UP,
	FORGOT,
	ALERT,
	GUIDE,
	CONTACT,
	LIST_RESULT,
	LIST_EVENT,
	CREATE_EVENT,
	SETTING,
	THEME,
	LIST_OPTION,
	CONTACT_HELP,
	INSTRUCT,
	SEND_EMAIL,
}

const initialState: IGeneralState = {
	fetching: false,
	modalType: null,
};
type IGeneralState = {
	fetching: boolean;
	modalType: ModalType | null;
};
export const GeneralSelectors = {
	selectModalType: (state: RootState) => state.general.modalType,
};

const generalSlice = createSlice({
	initialState,
	name: 'General',
	reducers: {
		getGeneralRequest: (state) => ({
			...state,
			fetching: true,
		}),
		getGeneralSuccess: (state) => ({
			...state,
			fetching: false,
		}),
		getGeneralFailure: (state) => ({
			...state,
			fetching: false,
		}),
		setModalType: (state, action: PayloadAction<ModalType | null>) => {
			state.fetching = false;
			state.modalType = action.payload;
		},
	},
});

export const GeneralActions = generalSlice.actions;
export const GeneralReducer = generalSlice.reducer;
