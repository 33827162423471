import { IUser, ServiceApi, setApiAuthorization } from '@/services';
import { isSuccess } from '@/utils/Api.util';

import { AuthSelector } from '../Auth';
import { ProfileActions } from './Profile.redux';

import { ApiResponse } from 'apisauce';
import { isNilOrEmpty } from 'ramda-adjunct';
import { put, select } from 'redux-saga/effects';

export function* getMyProfile() {
	try {
		const token: string = yield select(AuthSelector.selectToken);

		if (isNilOrEmpty(token)) {
			yield put(ProfileActions.getProfileFailure());
			return;
		}

		setApiAuthorization(token);

		const profile: ApiResponse<IUser> = yield ServiceApi.getUserMe();

		if (isSuccess(profile)) {
			yield put(ProfileActions.getProfileSuccess(profile?.data));
		} else {
			yield put(ProfileActions.getProfileFailure());
		}
	} catch (error) {
		yield put(ProfileActions.getProfileFailure());
	}
}
