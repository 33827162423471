import { GetEventListResponse, SettingItemResponse } from '@/services';
import { mapV2 } from '@/utils';

import {
	IEventItem,
	IEventJson,
	IResult,
	ResourceTypeEnum,
} from './LuckyDraw.type';

import { omit } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

export const formatEventDetail = (input: GetEventListResponse['data'][0]) => {
	return input;
};

export const formatEventList = (input: GetEventListResponse['data']) => {
	const eventList = [] as IEventItem[];
	let eventJson = {} as IEventJson;

	if (isNilOrEmpty(input)) {
		return {
			eventList,
			eventJson,
		};
	}

	for (let i = 0; i < input.length; i++) {
		const element = input[i];
		eventList.push({ id: element.id });
		eventJson = {
			...eventJson,
			[element.id]: formatEventDetail(element),
		};
	}

	return {
		eventList,
		eventJson,
	};
};

export const formatSetting = (input: SettingItemResponse) => {
	return {
		...omit(['typeDraw', 'prizes'], input),
		drawType: input?.typeDraw,
		resourceType: input?.resourceType ?? ResourceTypeEnum.RANGE_NUM,
		prizeList: mapV2(input?.prizes, (item) => ({
			id: item?.id,
			name: item?.name,
			resultCount: item?.resultCount,
			results: item?.results as unknown as IResult[],
		})),
		participants: mapV2(input?.participants, (item, index) => ({
			no: (index + 1)?.toString(),
			name: item?.name,
			code: item?.code,
			company: item?.company,
			position: item?.position,
			gender: item?.gender,
		})),
	};
};
